<template>
  <div class="topbg-wrapper">
    <div class="top-bg">
      <div class="top-header ">
        <div class="header-between flex-item"></div>
        <div class="header-center">
          {{ type === 1 ? $t("USDQ置換PPT") : $t("PPT置換USDQ") }}
        </div>
        <div class="header-between flex-item flex-end"></div>
      </div>
    </div>
    <el-form class="from-box">
      <div class="from-top flex-middle">
        <span>{{ type === 1 ? $t("USDQ置換") : $t("PPT置換") }}</span>
      </div>
      <el-form-item>
        <el-input
          type="number"
          :placeholder="$t('輸入兌換金額')"
          v-model="amount1"
          class="input-with-select"
          @input="changeAmount1"
        >
          <template #append>
            <div class="token-dropdown">{{ type === 1 ? "USDQ" : "PPT" }}</div>
          </template>
        </el-input>
      </el-form-item>
      <div class="change-box mt">
        <img
          class="change-img"
          src="~@/assets/image/pc/change.png"
          alt=""
          @click="exchangeType"
        />
      </div>

      <div class="from-top flex-middle">
        <span>{{ $t("兌換數量") }}</span>
      </div>
      <el-form-item>
        <el-input
          type="number"
          :placeholder="$t('輸入兌換金額')"
          v-model="amount2"
          class="input-with-select"
          @input="changeAmount2"
        >
          <template #append>
            <div class="token-dropdown">{{ type === 1 ? "PPT" : "USDQ" }}</div>
          </template>
        </el-input>
      </el-form-item>
      <div class="form-tips">
        {{ $t("置換價格") }} 1.000000 USDQ ≈ {{ price || "--" }} PPT
      </div>

      <div class="form-btn">
        <el-button
          type="primary"
          block
          round
          v-if="!address"
          @click="initWallet()"
          >{{ $t("鏈接錢包") }}</el-button
        >
        <el-button
          type="primary"
          block
          round
          v-else
          :disabled="loading"
          :loading="loading"
          @click="submit"
          >{{ $t("確認") }}</el-button
        >
      </div>
      <div class="tips-primary flex-middle">
        <img src="~@/assets/image/pc/tips.png" alt="" />
        <span class="primary-color">{{
          $t("每次兌換即按照置換價格進行兌換")
        }}</span>
      </div>
    </el-form>
  </div>
</template>

<script>
import { reactive, toRefs, computed, watch } from "vue";
import { getPriceApi } from "@/api";
import web3Data from "@/utils/initWeb3";
import { config } from "@/utils/config";
import tokenAbi from "@/utils/abi";
import { useStore } from "vuex";
import BigNumber from "bignumber.js";
import { ElMessage } from "element-plus";
import { useI18n } from "vue-i18n";
export default {
  components: {},
  setup() {
    const store = useStore();
    const { t } = useI18n();
    const data = reactive({
      web3: computed(() => store.state.provider),
      address: computed(() => store.state.accounts[0]),
      USDQBalance: 0,
      PPTBalance: 0,
      USDQContract: null,
      PPTContract: null,
      amount1: "",
      amount2: "",
      type: 1,
      price: "",
      loading: false,
      initContract: () => {
        data.USDQContract = new data.web3.eth.Contract(
          tokenAbi,
          config.USDQAddress
        );
        data.PPTContract = new data.web3.eth.Contract(
          tokenAbi,
          config.PPTAddress
        );
        data.getBalance();
      },
      getBalance: async () => {
        data.USDQBalance = await data.USDQContract.methods
          .balanceOf(data.address)
          .call();
        data.PPTBalance = await data.PPTContract.methods
          .balanceOf(data.address)
          .call();
      },
      exchangeType: () => {
        data.type = data.type === 1 ? 2 : 1;
        data.amount1 = "";
        data.amount2 = "";
      },
      changeAmount1: () => {
        if (data.type === 1) {
          data.amount2 = new BigNumber(data.amount1)
            .multipliedBy(data.price)
            .toFixed(6, 1);
        } else {
          data.amount2 = new BigNumber(data.amount1)
            .dividedBy(data.price)
            .toFixed(6, 1);
        }
      },
      changeAmount2: () => {
        if (data.type === 1) {
          data.amount1 = new BigNumber(data.amount2)
            .dividedBy(data.price)
            .toFixed(6, 1);
        } else {
          data.amount1 = new BigNumber(data.amount2)
            .multipliedBy(data.price)
            .toFixed(6, 1);
        }
      },
      getPrice: () => {
        getPriceApi().then(res => {
          data.price = res.data.usdtprice;
        });
      },
      initWallet: () => {
        web3Data.initWeb3();
      },
      submit: async () => {
        if (!Number(data.amount1)) {
          ElMessage.error(t("輸入兌換金額"));
          return;
        }
        let contract = null;
        if (data.type === 1) {
          if (new BigNumber(data.USDQBalance).lt(data.amount1)) {
            ElMessage.error(t("餘額不足"));
            return;
          }
          contract = data.USDQContract;
        } else {
          if (new BigNumber(data.PPTBalance).lt(data.amount1)) {
            ElMessage.error(t("餘額不足"));
            return;
          }
          contract = data.PPTContract;
        }
        let decimals = await contract.methods.decimals().call();
        let amount = new BigNumber(data.amount1)
          .multipliedBy(Math.pow(10, decimals))
          .toFixed();
        console.log(config.exchangeAddress, amount);
        data.loading = true;
        contract.methods
          .transfer(config.exchangeAddress, amount)
          .send({ from: data.address })
          .then(res => {
            data.loading = false;
            data.getBalance();
            data.amount1 = data.amount2 = "";
          })
          .catch(() => {
            data.loading = false;
          });
      }
    });
    if (data.address) {
      data.initContract();
    }
    watch(
      () => data.address,
      () => {
        if (data.address) {
          data.initContract();
        }
      }
    );
    data.getPrice();
    const refData = toRefs(data);
    return {
      ...refData
    };
  }
};
</script>
<style lang="scss" scoped>
.tips-primary {
  margin-bottom: 18px;
  img {
    display: block;
    width: 14px;
    height: 14px;
    margin-right: 7px;
  }
  span {
    font-size: 14px;
  }
}
.topbg-wrapper .from-box {
  margin-bottom: 50px;
}
/deep/ .el-input-group__append,
.el-input-group__prepend {
  width: 120px;
  background: #fff;
  border: 0;
}
/deep/ .el-input-group {
  border: 1px solid #dcdfe6;
  border-radius: 6px;
}
/deep/ .el-input__inner {
  border: 0;
}
/deep/ .el-input__inner:last-child {
  border: 1px solid #dcdfe6;
}
@media screen and (max-width: 480px) {
  .tips-primary span {
    font-size: 12px;
  }
  /deep/ .el-input-group__append,
  .el-input-group__prepend {
    width: 90px;
    background: #fff;
    border: 0;
  }
  /deep/ .el-input-group {
    border: 1px solid #dcdfe6;
    border-radius: 6px;
  }
  /deep/ .el-input__inner {
    border: 0;
  }
  /deep/ .el-input__inner:last-child {
    border: 1px solid #dcdfe6;
  }
  /deep/.el-select .el-input .el-select__caret {
    cursor: none;
  }
  /deep/ .is-focus {
    cursor: none !important;
  }
}
</style>
